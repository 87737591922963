/**
 * @Description: 首页
 * @author: Xiao'Hong
 * @date: 2021/2/22
 * @fileName: home
 */
export default [
    {
        path: "/",
        redirect: '/home'
    },
    {path: "/home", component: () => import('@/views/home/home.vue')}, //首页
    {path: "/newsCenter", component: () => import('@/views/home/newsCenter')}, //新闻中心
]
