/**
 * @Description: 案例
 * @author: Xiao'Hong
 * @date: 2021/2/22
 * @fileName: case
 */
export default [
    {
        path: "/case",
        component: () => import('@/views/case/case.vue')
    },
]
