/**
 * @Description:   系统
 * @author: Xiao'Hong
 * @date: 2021/3/8
 * @fileName: sys.js
 */

export default [{
    path: '/sys',
    component: () =>
        import ('@/components/sys/Main'),
    children: [{
            path: '/sys',
            name: '/sys',
            meta: { is: true },
            component: () =>
                import ('@/views/sys/sys')
        },
        {
            path: '/sys/solution',
            name: '/sys/solution',
            meta: { is: true },
            component: () =>
                import ('@/views/sys/solution')
        },
        {
            path: '/sys/product',
            name: '/sys/product',
            meta: { is: true },
            component: () =>
                import ('@/views/sys/product')
        },
        {
            path: '/sys/case',
            name: '/sys/case',
            meta: { is: true },
            component: () =>
                import ('@/views/sys/case')
        },
        {
            path: '/sys/news',
            name: '/sys/news',
            meta: { is: true },
            component: () =>
                import ('@/views/sys/news')
        },
    ]
}, ]