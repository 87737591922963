<template>
  <router-view/>
</template>


<script>

export default {


  created() {
    if ( this.isMobile() ) location.href = `http://www.huxiawang.com/mobile/`;
  },
  mounted() {
  },
  methods: {
    isMobile() {
      let flag = navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  }
}

</script>


<!--suppress CssUnknownTarget -->
<style lang="less">

@import url("//at.alicdn.com/t/font_2386419_bjqn45kak2.css");

.icon {
  font-family: iconfont;
  font-size: 45px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}


.btnBg {
  background-color: #1777ec !important;
}

.opacity {
  opacity: 1;
}


[v-cloak] {
  display: none;
}


blockquote, body, button, dd, dl, dt, fieldset, form, h1, h2, h3, h4, h5, h6, hr, input, legend, li, ol, p, pre, td, textarea, th, ul {
  margin: 0;
  padding: 0
}

div, input {
  box-sizing: border-box;
}

ul, ol, li {
  list-style: none;
}

.nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cursor {
  cursor: pointer;
}

button, input, select, textarea {
  font-size: 100%;
  outline: none !important;
}

a {
  text-decoration: none;
  color: #000;
}

.overflow {
  overflow: hidden;
}

</style>
