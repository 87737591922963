import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        Iscollapse: false //侧边栏

    },
    mutations: {
        // 侧边栏
        ChangeIscollapse(state, collapse) {
            state.Iscollapse = collapse
        }
    },
    actions: {},
    modules: {}
})
