import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import animated from 'animate.css';
import "lib-flexible-computer";
import cookie from 'vue-cookies';


Vue.config.productionTip = false

Vue.use(ElementUI);
Vue.use(animated)
Vue.use(cookie)


Vue.filter('filterText', function (v) {
    return v.replace(/\<\/?p\>/g, '')
})


new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')




