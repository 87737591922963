import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}


Vue.use(VueRouter);

import home from "./modules/home";          //首页
import product from "./modules/product";    // 产品
import news from "./modules/news";          // 新闻
import solution from "./modules/solution";  // 解决方案
import we from "./modules/we";              // 我们
import cases from "./modules/case";         // 案例
import sys from "./modules/sys";

const routes = [
    {
        path: "/",
        component: () => import('@/views/main.vue'),
        children: [...home,
            ...product,
            ...news,
            ...solution,
            ...we,
            ...cases,
        ]
    },
    ...sys,
    {
        path: '/mgmlogin',
        component: () => import('@/views/sys/login'),
    },
    {
        path: '*', redirect: '/'
    }
]


const router = new VueRouter({
    routes,
    base: process.env.BASE_URL,
    mode: "history"
});


/**
 * 路由导航守卫
 */
router.beforeEach((to, from, next) => {

    const token = Vue.$cookies.get('token');

    if (to.path.indexOf('/sys') > -1 && !token) return next('/');

    next();

})

export default router;
